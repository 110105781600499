.footer {
    background-color: #ff0000;
    /* Red background */
    color: #ffffff;
    /* White text */
}

.footer a {
    color: #ffffff;
    /* Ensure links are white */
}

/* .footer a:hover {
    color: #ffdd00; 
} */

.btn-outline-light {
    color: #ffffff;
    border-color: #ffffff;
}

/* .btn-outline-light:hover {
    color: #ffdd00;
    border-color: #ffdd00;
} */

.footer-menu a {
    margin-right: 15px;
    text-decoration: none;
    color: #ffffff;
}

.footer-menu a:hover {
    color: #ffdd00;
    text-decoration: underline;
}

.btn-link.text-white {
    color: #ffffff;
    text-decoration: none;
}

.btn-link.text-white:hover {
    color: #ffdd00;
    text-decoration: underline;
}

/* Hide the back-to-top button on smaller screens */
@media (max-width: 768px) {
    .back-to-top {
        display: none !important;
    }
}

.social-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.social-icons a {
    margin-right: 8px;
    /* Adjust as needed */
    margin-bottom: 8px;
    /* Adjust as needed */
}